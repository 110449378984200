import { get, post, url } from '../../service/ApiDecorator'

class Jr {
    // 页头页尾信息
    @url('/schoolsite/api/38/getSetInfoBySid')
    @post
    HF() {}

    // 图片新闻
    @url('/schoolsite/api/38/getPicArts')
    @get
    ImgNews() {}

    // 轮播
    @url('/schoolsite/api/38/getPics')
    @get
    Banner() {}

    // 导航栏
    @url('/schoolsite/api/38/getTypes')
    @get
    Nav() {}

    // 学校新闻栏目,部门动态,校务公开,工作计划
    @url('/schoolsite/api/38/getArts')
    @get
    Xxxw() {}

    // 应用中心
    @url('/schoolsite/api/38/getTypes')
    @get
    Yycenter() {}

    // 友情链接
    @url('/schoolsite/api/38/getUrls')
    @get
    FriendshipLinks() {}

    // 文章详情
    @url('/schoolsite/api/:id/getArtInfo')
    @post
    Details() {}

    // 搜索
    @url('schoolsite/api/38/getLikeArts')
    @post
    Search() {}

    // 浏览数
    @url('/schoolsite/api/38/addCount')
    @post
    Count() {}
}

export default new Jr()
