<template>
    <div id="top">
        <div class="header-box">
            <div class="header-div">
                <div class="header-top">
                    <img src="static/jr/logo2.png" alt="" class="logo">
                    <p v-for="(item,index) in columnList" :key="index"><span></span>{{item.title}}</p>
                    <!-- <p><span></span>{{tit}}</p> -->
                </div>
            </div>
        </div>
        <div class="nav">
            <el-menu :default-active="activeIndex"
                class="el-menu-demo subpage-nav"
                mode="horizontal"
                @select="handleSelect"
                background-color="#fff"
                text-color="#333"
                active-text-color="#333">
                <div class="index-url" @click="indexUrl()">首页</div>
                <template v-for="(item,index) in nav">
                    <el-submenu :index="(index+1).toString()" :key="index">
                        <template slot="title"><span @click="tiao(item.title,item.id,item.pid),tiao2(item.title,'654','479','开放大学'),tiao3(item.title,'655','465','党建之窗'),oldJr(item.title,item.tzurl)">{{item.title}}</span></template>
                        <el-menu-item :index="index+1+'-'+index" v-for="(val,i) in item.stypes" :key="i"  @click="hrefUrl(val.tzurl,val.id,val.pid,val.title)">{{val.title}}</el-menu-item>
                    </el-submenu>
                </template>
            </el-menu>
        </div>
    </div>
</template>

<script>
import JrApi from '../../../lvya-common/src/api/eos/jr'
export default {
    name: 'top',
    data() {
        return {
            status: false,
            isCustomers: -1,
            activeIndex: '1',
            nav: [],
            columnList: []
        }
    },
    created() {
        this.Nav()
        this.getId()
        this.ColumnList()
    },
    watch: {
        '$route'(to, from) {
            this.pid = this.$route.query.pid
            this.id = this.$route.query.id
            this.tit = this.$route.query.tit
            this.ColumnList()
            this.Nav()
        //    console.log(this.$route.query.pid, this.$route.query.id)
        }
    },
    methods: {
        handleSelect(key, keyPath) {
            // console.log(key, keyPath)
        },
        async Nav() {
            const resData = await JrApi.Nav()
            this.nav = resData
            // console.log(this.nav)
        },
        getId() {
            this.pid = this.$route.query.pid
            this.id = this.$route.query.id
            this.tit = this.$route.query.tit
            // console.log(this.tit)
        },
        // 跳转二级页面
        hrefUrl(url, id, pid, tit) {
            if (url == '' || url == null) {
                console.log(id)
                this.$router.push({ path: '/indexList', query: { id, pid, tit }})
            } else {
                this.$router.push({ path: url, query: { id, tit }})
            }
        },
        indexUrl() {
            this.$router.push({ path: '/home' })
        },
        tiao(tit, id, pid) {
            if (tit == '项目创建') {
                this.$router.push({ path: '/projectCreation', query: { id, pid }})
            }
        },
        tiao2(tit1, id, pid, tit) {
            if (tit1 == '开放大学') {
                this.$router.push({ path: '/subpage', query: { id, pid, tit }})
            }
        },
        tiao3(tit1, id, pid, tit) {
            if (tit1 == '党建之窗') {
                this.$router.push({ path: '/subpage', query: { id, pid, tit }})
            }
        },
        oldJr(tit1, url) {
            if (tit1 == '旧版网站') {
                window.open(url, '_blank')
                // this.$router.push({ path: '/subpage' })
            }
        },
        async ColumnList() {
            const resData = await JrApi.Yycenter({ pid: this.pid })
            this.columnList = resData
            // console.log(this.columnList)
        },
    },
    mounted() {
    },
}
</script>

<style scope>
@font-face {
 font-family: 'qigong';
 src: url('../../../font/qigong.TTF');
}
.header-box{
    width: 100%;
}
.header-div{
    width: 1200px;
    margin: 0 auto;
}
.header-top{
    height: 120px;
    display: flex;
}
.header-top img{
    width: 500px;
    height: 90px;
    margin-top: 10px;
}
.header-top p{
    font-size: 26px;
    color: #ec1d25;
    font-family: qigong;
    font-weight: 600;
    line-height: 110px;
}
.header-top p span{
    width: 8px;
    height: 8px;
    background: #ec1d25;
    border-radius: 50%;
    display: inline-block;
    margin: 30px 15px 0 15px;
}
.nav{
    width: 100%;
    background: #881f26;
    margin-top: 0px!important;
}
.nav ul{
    width: 1200px;
    margin: 0 auto;
    background-color: #881f26!important;
}
.el-menu.el-menu--horizontal {
    border-bottom: none;
}
 .nav ul li{
    color: #fff!important;
    background-color: #881f26!important;
}
.subpage-nav{
    display: flex;
}
.subpage-nav .el-submenu__title{
    background-color: #881f26!important;
    color: #fff!important;
}
.subpage-nav .el-submenu.is-active .el-submenu__title {
    border-bottom: 2px solid #fff;
    border-bottom-color: rgb(255, 255, 255)!important;
}
.el-menu-item.is-active{
    border-bottom-color: rgb(255, 255, 255)!important;
}
.subpage-nav .el-submenu__title i{
    color: #fff!important;
}
.index-url{
    height: 60px;
    line-height: 60px;
    color: #fff;
    font-size: 14px;
    padding: 0 15px;
    box-sizing: border-box;
    outline: none;
    cursor: pointer;
}
</style>
